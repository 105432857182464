<template>
  <div class="cart">
    <div class="cart__left">
      <div class="cart__content">
        <h1 class="text-4xl my-8 font-medium">Shopping cart</h1>
        <p>{{ $store.state.cart.length || "No" }} products in cart.</p>
        <CartItem
          v-for="item in $store.state.cart"
          :key="`cartitem-${item.id}`"
          :itemId="item.id"
          :qty="item.quantity"
        />
      </div>
    </div>
    <div class="cart__right">
      <div class="cart__content">
        <div class="flex flex-col gap-4">
          <div v-if="checkout_btn !=='Confirm Payment'">
            <div class="flex justify-between">
              <p>Products in cart</p>
              <p class="font-price">Kes{{ totalProducts }}</p>
            </div>
            <div class="flex justify-between">
              <p>Delivery</p>
              <p class="font-price">Kes{{ shippingPrice }}</p>
            </div>
            <div class="separator"></div>
            <div class="flex justify-between">
              <p class="text-2xl">Total</p>
              <p class="font-price text-2xl">Kes{{ totalPrice }}</p>
            </div>
<!--            <div>-->
<!--              <base-input-->
<!--                  fullWidth-->
<!--                  label="Mpesa Phone number"-->
<!--                  v-model="mobile_number"-->
<!--                  :error="errors.mobile_number"-->
<!--                  type="text"-->
<!--                  id="mobile-pw"-->
<!--              />-->
<!--            </div>-->
            <base-button class="mt-4"  @click="handleSubmit" v-if="totalProducts > 0" size="lg" fullWidth
            >{{checkout_btn}}</base-button
            >
          </div>
          <div v-else>
            <div class="px-6 py-4">
              <div class="font-bold text-green-500 text-xl mb-2">Order Placed Successfully</div>
              <p class="text-gray-900 text-base">
             To make payment send money to the below mpesa details:<br>
              </p>
              <p class="text-gray-700 text-base">
                <b>Till Number:</b>{{till}}<br>
                <b>Paybill Number:</b> {{paybill}}<br>
                <b>Account No:</b> {{account_no}}
              </p>
            </div>
          </div>

<!--          <base-button  @click="handleSubmit" v-if="totalProducts > 0" size="lg" fullWidth-->
<!--            >{{checkout_btn}}</base-button-->
<!--          >-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import CartItem from '@/components/molecules/CartItem.vue';
import router from '@/router';
import axios from 'axios';

export default defineComponent({
  components: { CartItem },
  data() {
    return {

    };
  },
  setup() {
    const store = useStore();
    const shippingPrice = ref<number>(0);
    const checkout_btn = ref('Make Order');
    const account_no = ref('');
    const till = ref('');
    const paybill = ref('');
    let order_id = '';
    const formData = reactive({
      mobile_number: '',
      errors: {
        mobile_number: false,
      }
    });

    function resetErrors() {
      formData.errors.mobile_number = false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // is phone valid
      const phoneRegex = /^0(7(?:(?:[129][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/;
      if (!phoneRegex.test(formData.mobile_number)) {
        errors.push('mobile_number');
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    const totalProducts = computed(() =>
      store.state.cart.reduce((acc, curr) => {
        // console.log('cureent',curr)
        const   price   = store.getters.getProductById(curr.id);

        console.log(price);
        return acc + (parseInt(price.price)*curr.quantity);
      }, 0)
    );

    const confirmation = computed(
        () =>  store.state.confirmation
    );

    const totalPrice = computed(
      () => totalProducts.value + shippingPrice.value
    );

    function handleSubmit() {
      // const isValid = validateForm();
      // if (!isValid) return;

      function getKeyOrDefault( key: string, defaultValue = '' ) {
        return localStorage.getItem( key ) || defaultValue;
      }
      if(checkout_btn.value === 'Make Order') {
        const user = JSON.parse(getKeyOrDefault('user'));
        console.log("user",user.store_id);
        const form = new FormData();
        const _product = store.state.cart;
        form.append('store_id', user.store_id);
        form.append('products', JSON.stringify(_product));
        // form.append('quantity', '1');
        // form.append('product_id', _product.id.toString());
        form.append('customer_mobile', '254' + formData.mobile_number.substring(formData.mobile_number.length - 9));
        // form.append('customer_mobile','254723840636');
        form.append('grand_total_price', totalPrice.value.toString());
        // form.append('product_rate', _product.amount.toString());
        // form.append('details', 'product purchased');
        form.append('paid_amount', totalPrice.value.toString());
        form.append('customer_id', user.customer_id);
        axios.post('/neworder', form).then(function (response) {
          console.log('success', response);
          if (response.data.result == 'ok')
          {
            store.dispatch('clearCart');
            console.log(response.data)
            order_id = response.data.order.order_id;
            checkout_btn.value = 'Confirm Payment';
            paybill.value = response.data.order.paybill;
            till.value= response.data.order.till;
            account_no.value= response.data.order.payacc;
          }else
          {
            store.commit('addToast', {
              type: 'error',
              message:
                  'A Server error occurred, try again'
            });
          }
        }).catch(function (error) {
          console.log('error', error);
        });
      }
      else
      {
        const form2 = new FormData();
        form2.append('order_id', order_id);
        axios.post('/order_status', form2).then(function (response) {

          if(response.data.result == 'ok')
          {
            store.commit('addToast', {
              type: 'success',
              message:
                  'Payment confirmed successfully'
            });
            store.dispatch('clearCart');
            router.push('/');
          }else
          {
            store.commit('addToast', {
              type: 'error',
              message:
                  'Payment not confirmed,pay and try again after a minute'
            });
          }

        }).catch(function (error) {
          // checkout_btn = 'Make Order';
          store.commit('addToast', {
            type: 'error',
            message:
                'Payment not confirmed,pay and try again after a minute'
          });
        });

      }
    }

    return {...toRefs(formData),till,paybill,account_no, handleSubmit,checkout_btn, totalProducts,confirmation, shippingPrice, totalPrice };
  },
  methods: {
    /*handleSubmit() {*/
    /*  console.log('we got here');*/
    /*    const form = new FormData();*/
    /*    // quantity:1*/
    /*    // product_id:95327499*/
    /*    // customer_mobile:254723840636*/
    /*    // grand_total_price:2*/
    /*    // product_rate:1*/
    /*    // details:Product Purchased*/
    /*    // paid_amount:2*/
    /*    // customer_id:3*/
    /*    let _product = this.$store.state.cart[0];*/
    /*    form.append('quantity','1');*/
    /*    form.append('product_id',_product.id.toString());*/
    /*    form.append('customer_mobile','254'+this.mobile_number.substring(this.mobile_number.length - 9));*/
    /*    // form.append('customer_mobile','254723840636');*/
    /*    form.append('grand_total_price',this.totalPrice.toString());*/
    /*    form.append('product_rate',_product.amount.toString());*/
    //     form.append('details','product purchased');
    //     form.append('paid_amount',this.totalPrice.toString());
    //     form.append('customer_id','UPTC7SXI3PMVS5L');
    //     axios.post('/neworder', form).then(function (response) {
    //       console.log('success',response);
    //     }).catch(function (error) {
    //       console.log('error',error);
    //     });
    //
    //     // this.$store.commit('addToast', {
    //     //   message: 'Checkout success'
    //     // });
    //     //
    //     // this.checkout_btn = 'Confirm Payment';
    //     // router.push('/');
    // }
  }

});
</script>

<style lang="postcss" scoped>
.cart {
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  row-gap: 3rem;
}

.cart__right {
  @apply bg-background-light min-h-summary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart__right .cart__content {
  @apply md:flex md:justify-center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.cart__content > * {
  width: 100%;
  max-width: 25rem;
}

.separator {
  @apply border-b-2;
}

.cart__left .cart__content {
  @apply justify-center md:justify-start;
}
</style>
<style lang="scss" scoped>
.cart__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
  width: 100%;

  @media (min-width: 640px) {
    max-width: calc(640px / 2);
  }

  @media (min-width: 768px) {
    max-width: calc(768px / 2);
  }

  @media (min-width: 1024px) {
    max-width: calc(1024px / 2);
  }

  @media (min-width: 1280px) {
    max-width: calc(1280px / 2);
  }
}

.cart__left > * {
  margin-left: auto;
}

.cart__right > * {
  margin-right: auto;
}
</style>
