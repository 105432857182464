<template>
  <div class="item">
    <div
      class="item__img"
      :style="{
        backgroundImage: `url(${api +'/'+ itemData.image_thumb})`
      }"
      :alt="itemData.product_name"
    >
      <span class="sr-only"
        >Picture of {{ itemData.brand_id }} {{ itemData.product_name }}</span
      >
    </div>
<!--    <router-link :to="`/product/${itemData.id}`">-->
      <div class="item__content">
        <h2 class="text-lg">{{ itemData.product_name }}</h2>
        <h3>{{ itemData.brand_id }}</h3>
        <p class="font-price text-2xl mt-6">Kes{{ itemData.price }}</p>
        <div class="flex justify-end gap-4">
              <div class="flex items-center justify-center ">
                <svg
                    @click="$store.dispatch('decreamentCartQty', itemData)"
                    class="fill-current text-gray-600 w-3" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                </svg>

                <input class="mx-2 border text-center w-8 h-8" type="text" :value="qty">

                <svg
                    @click="$store.dispatch('increamentCartQty', itemData)"
                    class="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                  <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                </svg>
              </div>
<!--          <button type="button" @click="removeItem()" class="font-medium text-red-600 hover:text-red-500">Remove</button>-->
        </div>
      </div>
<!--    </router-link>-->
    <div class="item__controls">
      <button
        @click="$store.dispatch('addFavourite', itemData.id)"
        class="item__btn"
      >
        <icon :name="favourite ? 'heart' : 'heart-outline'" />
      </button>
      <button
        @click="$store.dispatch('removeFromCart', itemData.product_id)"
        class="item__btn--secondary"
      >
        <icon name="trash-2-outline" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: { itemId: Number,qty:Number },
  setup(props) {
    const store = useStore();
    const api = computed(() => process.env.VUE_APP_API_URL);
    const itemData = computed(() => store.getters.getProductById(props.itemId));
    const favourite = computed(() =>
      store.state.favourites.includes(itemData.value.id)
    );
    return { itemData, api, favourite };
  }
});
</script>

<style lang="postcss" scoped>
.item {
  @apply gap-4 my-8;
  display: grid;
  grid-template-columns: 4rem 1fr 1rem;
}

.item__content {
  @apply flex flex-col;
}

.item__controls {
  @apply flex flex-col justify-between;
}

.item__img {
  @apply h-16 w-16 bg-cover bg-center;
}

.item__btn {
  @apply hover:opacity-50;
}

.item__btn--secondary {
  @apply opacity-50 hover:opacity-100;
}
</style>
