
import { defineComponent, ref } from "vue";
import Drawer from "@/components/organisms/Drawer.vue";
import AllProducts from "@/views/AllProducts.vue";
import AddProduct from "@/views/AddProduct.vue";
import Settings from "@/views/Settings.vue";

const tabs = {
  general: "GENERAL",
  add: "ADD",
  edit: "EDIT"
};

export default defineComponent({
  components: {
    Drawer,
    AllProducts,
    AddProduct,
    Settings
  },
  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    setTab(tab: keyof typeof tabs) {
      this.tabOpen = tab;
    }
  },
  setup() {
    const tabOpen = ref(tabs.edit);
    const isDrawerOpen = ref(true);
    const apiURL = ref(process.env);
    return {
      tabs,
      isDrawerOpen,
      tabOpen,
      apiURL
    };
  }
});
