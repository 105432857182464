
import { defineComponent, ref, reactive, computed, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import CartItem from '@/components/molecules/CartItem.vue';
import router from '@/router';
import axios from 'axios';

export default defineComponent({
  components: { CartItem },
  data() {
    return {

    };
  },
  setup() {
    const store = useStore();
    const shippingPrice = ref<number>(0);
    const checkout_btn = ref('Make Order');
    const account_no = ref('');
    const till = ref('');
    const paybill = ref('');
    let order_id = '';
    const formData = reactive({
      mobile_number: '',
      errors: {
        mobile_number: false,
      }
    });

    function resetErrors() {
      formData.errors.mobile_number = false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // is phone valid
      const phoneRegex = /^0(7(?:(?:[129][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/;
      if (!phoneRegex.test(formData.mobile_number)) {
        errors.push('mobile_number');
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    const totalProducts = computed(() =>
      store.state.cart.reduce((acc, curr) => {
        // console.log('cureent',curr)
        const   price   = store.getters.getProductById(curr.id);

        console.log(price);
        return acc + (parseInt(price.price)*curr.quantity);
      }, 0)
    );

    const confirmation = computed(
        () =>  store.state.confirmation
    );

    const totalPrice = computed(
      () => totalProducts.value + shippingPrice.value
    );

    function handleSubmit() {
      // const isValid = validateForm();
      // if (!isValid) return;

      function getKeyOrDefault( key: string, defaultValue = '' ) {
        return localStorage.getItem( key ) || defaultValue;
      }
      if(checkout_btn.value === 'Make Order') {
        const user = JSON.parse(getKeyOrDefault('user'));
        console.log("user",user.store_id);
        const form = new FormData();
        const _product = store.state.cart;
        form.append('store_id', user.store_id);
        form.append('products', JSON.stringify(_product));
        // form.append('quantity', '1');
        // form.append('product_id', _product.id.toString());
        form.append('customer_mobile', '254' + formData.mobile_number.substring(formData.mobile_number.length - 9));
        // form.append('customer_mobile','254723840636');
        form.append('grand_total_price', totalPrice.value.toString());
        // form.append('product_rate', _product.amount.toString());
        // form.append('details', 'product purchased');
        form.append('paid_amount', totalPrice.value.toString());
        form.append('customer_id', user.customer_id);
        axios.post('/neworder', form).then(function (response) {
          console.log('success', response);
          if (response.data.result == 'ok')
          {
            store.dispatch('clearCart');
            console.log(response.data)
            order_id = response.data.order.order_id;
            checkout_btn.value = 'Confirm Payment';
            paybill.value = response.data.order.paybill;
            till.value= response.data.order.till;
            account_no.value= response.data.order.payacc;
          }else
          {
            store.commit('addToast', {
              type: 'error',
              message:
                  'A Server error occurred, try again'
            });
          }
        }).catch(function (error) {
          console.log('error', error);
        });
      }
      else
      {
        const form2 = new FormData();
        form2.append('order_id', order_id);
        axios.post('/order_status', form2).then(function (response) {

          if(response.data.result == 'ok')
          {
            store.commit('addToast', {
              type: 'success',
              message:
                  'Payment confirmed successfully'
            });
            store.dispatch('clearCart');
            router.push('/');
          }else
          {
            store.commit('addToast', {
              type: 'error',
              message:
                  'Payment not confirmed,pay and try again after a minute'
            });
          }

        }).catch(function (error) {
          // checkout_btn = 'Make Order';
          store.commit('addToast', {
            type: 'error',
            message:
                'Payment not confirmed,pay and try again after a minute'
          });
        });

      }
    }

    return {...toRefs(formData),till,paybill,account_no, handleSubmit,checkout_btn, totalProducts,confirmation, shippingPrice, totalPrice };
  },
  methods: {
    /*handleSubmit() {*/
    /*  console.log('we got here');*/
    /*    const form = new FormData();*/
    /*    // quantity:1*/
    /*    // product_id:95327499*/
    /*    // customer_mobile:254723840636*/
    /*    // grand_total_price:2*/
    /*    // product_rate:1*/
    /*    // details:Product Purchased*/
    /*    // paid_amount:2*/
    /*    // customer_id:3*/
    /*    let _product = this.$store.state.cart[0];*/
    /*    form.append('quantity','1');*/
    /*    form.append('product_id',_product.id.toString());*/
    /*    form.append('customer_mobile','254'+this.mobile_number.substring(this.mobile_number.length - 9));*/
    /*    // form.append('customer_mobile','254723840636');*/
    /*    form.append('grand_total_price',this.totalPrice.toString());*/
    /*    form.append('product_rate',_product.amount.toString());*/
    //     form.append('details','product purchased');
    //     form.append('paid_amount',this.totalPrice.toString());
    //     form.append('customer_id','UPTC7SXI3PMVS5L');
    //     axios.post('/neworder', form).then(function (response) {
    //       console.log('success',response);
    //     }).catch(function (error) {
    //       console.log('error',error);
    //     });
    //
    //     // this.$store.commit('addToast', {
    //     //   message: 'Checkout success'
    //     // });
    //     //
    //     // this.checkout_btn = 'Confirm Payment';
    //     // router.push('/');
    // }
  }

});
