
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ProductCard from "@/components/molecules/ProductCard.vue";

export default defineComponent({
  components: {
    ProductCard
  },
  setup() {
    const errors = ref<string[]>([]);
    const route = useRoute();
    const store = useStore();
    const routeId = computed(() => route.params.id);
    const formData = reactive({
      description: "",
      title: "",
      brand: "",
      rating: 0,
      category: "",
      price: 0,
      featured: false,
      image_url: "/uploads/photo_1491553895911_0055eca6402d_eaf84a6eb4.jpeg"
    });

    function setInitialValues() {
      if (!route.params.id) return;
      const initialValues = route.params.id
        ? store.getters.getProductById(route.params.id)
        : null;
      if (!initialValues) return;
      formData.description = initialValues.description;
      formData.title = initialValues.title;
      formData.brand = initialValues.brand;
      formData.rating = initialValues.rating;
      formData.category = initialValues.category;
      formData.price = initialValues.price;
      formData.featured = initialValues.featured;
      formData.image_url = initialValues.image_url || initialValues.image.url;
    }
    setInitialValues();
    watch(routeId, val => {
      setInitialValues();
    });
    return {
      formData,
      errors
    };
  },
  methods: {
    validateForm() {
      this.errors = [];
      if (!this.formData.title.length) this.errors.push("title");

      if (!this.formData.brand.length) this.errors.push("brand");

      if (this.formData.rating < 0 || this.formData.rating > 5)
        this.errors.push("rating");

      if (!this.formData.description.length) this.errors.push("description");

      if (!this.formData.category.length) this.errors.push("category");

      if (this.formData.price <= 0) this.errors.push("price");

      if (this.errors.length) return false;
      return true;
    },
    handleSubmit() {
      // validate
      if (!this.validateForm()) return;
      const payload = { ...this.formData };
      if (this.$route.params.id)
        return this.$store.dispatch("updateProduct", {
          ...this.formData,
          id: this.$route.params.id
        });

      this.$store.dispatch("addProduct", payload);
    }
  },
  computed: {
    previewData(): {
      [key: string]: any;
    } {
      const obj = {
        title: this.formData.title,
        brand: this.formData.brand,
        price: this.formData.price,
        image_url: this.formData.image_url,
        image: { formats: { thumbnail: { url: this.formData.image_url } } }
      };
      return obj;
    }
  }
});
