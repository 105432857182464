
import { defineComponent } from "vue";
import ProductCard from "@/components/molecules/ProductCard.vue";

export default defineComponent({
  components: { ProductCard },
  setup() {
    return {};
  }
});
