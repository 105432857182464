
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: { itemId: Number,qty:Number },
  setup(props) {
    const store = useStore();
    const api = computed(() => process.env.VUE_APP_API_URL);
    const itemData = computed(() => store.getters.getProductById(props.itemId));
    const favourite = computed(() =>
      store.state.favourites.includes(itemData.value.id)
    );
    return { itemData, api, favourite };
  }
});
