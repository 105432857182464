
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      formData: {
        heroTitle: this.$store.state.hero?.hero_title,
        heroText: this.$store.state.hero?.hero_text,
        heroRoute: this.$store.state.hero?.hero_route,
        heroBtnText: this.$store.state.hero?.hero_btn_text,
        heroBanner: this.$store.state.hero?.hero_banner.url,
        heroImageUrl: this.$store.state.hero?.hero_image_url
      }
    };
  },
  methods: {
    submitForm() {
      const payload = {
        hero_title: this.formData.heroTitle,
        hero_text: this.formData.heroText,
        hero_route: this.formData.heroRoute,
        hero_btn_text: this.formData.heroBtnText,
        hero_banner: {
          url: this.formData.heroBanner
        },
        hero_image_url: this.formData.heroImageUrl
      };
      this.$store.dispatch("setHeroData", payload);
    }
  },
  setup() {
    return {};
  }
});
